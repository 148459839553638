var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container"
  }, [_c('van-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticStyle: {
      "margin": "20px 0"
    }
  }, [_c('van-field', {
    attrs: {
      "readonly": "",
      "label": "费用类型",
      "input-align": "right"
    },
    model: {
      value: _vm.typeName,
      callback: function ($$v) {
        _vm.typeName = $$v;
      },
      expression: "typeName"
    }
  }), _c('van-field', {
    attrs: {
      "label": "费用描述",
      "input-align": "right",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _c('van-field', {
    attrs: {
      "readonly": "",
      "clickable": "",
      "label": "租赁信息",
      "placeholder": "请选择",
      "input-align": "right",
      "right-icon": "arrow",
      "required": "",
      "rules": [{
        required: true,
        message: '租赁信息不能为空'
      }]
    },
    on: {
      "click": function ($event) {
        _vm.showPicker = true;
      }
    },
    model: {
      value: _vm.form.rentName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rentName", $$v);
      },
      expression: "form.rentName"
    }
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showPicker,
      callback: function ($$v) {
        _vm.showPicker = $$v;
      },
      expression: "showPicker"
    }
  }, [_c('van-picker', {
    attrs: {
      "title": "选择租赁信息",
      "value-key": "name",
      "show-toolbar": "",
      "columns": _vm.contractData
    },
    on: {
      "cancel": function ($event) {
        _vm.showPicker = false;
      },
      "confirm": _vm.handleChange
    }
  })], 1), _c('van-field', {
    attrs: {
      "type": "number",
      "label": "费用金额",
      "input-align": "right",
      "required": "",
      "rules": [{
        required: true,
        message: '费用金额不能为空'
      }],
      "placeholder": "请输入"
    },
    on: {
      "input": e => _vm.form.amount = _vm.prohibitNegative(e, 2, 10)
    },
    model: {
      value: _vm.form.amount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  }), _c('van-field', {
    attrs: {
      "rows": "2",
      "autosize": "",
      "label": "备注",
      "type": "textarea",
      "maxlength": "30",
      "placeholder": "请输入备注",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1), _c('div', {
    staticStyle: {
      "margin": "50px 20px"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("下一步")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };